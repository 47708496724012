import { SlideDirection } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import Filters from '../GamesCatalog/Filters';
import { MotionBox } from '../Motion';
type UserMenuProps = PropsWithChildren & {
  placement?: SlideDirection;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const CatalogFilter = ({
  placement = 'left',
  children,
  onClose,
  onOpen,
  isOpen
}: UserMenuProps) => {
  return (
    <>
      {children}
      <MotionBox
        marginTop="2rem"
        width="100%"
        animate={{
          position: 'relative',
          opacity: 1,
          visibility: 'visible'
        }}
        initial={{
          position: 'absolute',
          opacity: 0,
          visibility: 'hidden'
        }}
      >
        {isOpen ? <Filters /> : null}
      </MotionBox>
    </>
  );
};

export default CatalogFilter;
